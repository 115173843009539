// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import BackgroundImage from 'components/core/images/background-image';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundFluidImage: Object,
  description: ?string,
  title: ?string
|};

/**
 * `Section` styled component.
 */

const Section = styled.header`
  position: relative;
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  margin-bottom: ${units(4)};
  padding-top: ${units(15)};

  ${media.min('ms')`
    margin-bottom: ${units(6)};
    padding-top: ${units(14)};
  `}

  ${media.min('md')`
    padding-top: ${units(26)};
  `}
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  bottom: -200px !important;

  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { backgroundFluidImage, description, title } = props;

  return (
    <Section>
      <StyledBackgroundImage fluid={backgroundFluidImage} />

      <Container>
        <StyledGrid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnLg={'4 / -4'}
            gridColumnMd={'3 / -3'}
            gridColumnMs={'2 / -2'}
            gridRow={1}
            justifyContent={'center'}
            textAlign={'center'}
          >
            {title && (
              <Type.H1
                color={color('secondary')}
                marginBottom={units(3)}
              >
                <RawHtml>
                  {title}
                </RawHtml>
              </Type.H1>
            )}

            {description && (
              <Type.H5
                as={'h2'}
                color={color('secondary')}
              >
                <RawHtml>
                  {description}
                </RawHtml>
              </Type.H5>
            )}
          </Box>
        </StyledGrid>
      </Container>
    </Section>
  );
}

/**
 * Export `Header` component.
 */

export default Header;

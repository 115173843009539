// @flow

/**
 * Module dependencies.
 */

import type { TemplateProps } from 'types/template';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { groupBy, map } from 'lodash';
import { resolve } from 'url';
import CategorizedStories from 'components/payspaces/categorized-stories';
import FeaturedPayspacesList from 'components/payspaces/featured-payspaces-list';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/payspaces/header';
import PageContainer from 'components/page-container';
import React, { type Node, useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Config query.
 */

const configQuery = graphql`
  query {
    config {
      api {
        slyk {
          baseUrl
          endpoints {
            featuredDomains
          }
        }
      }
      app {
        baseUrl
      }
    }
    categories: allSeegnoCmsCategoryEntity {
      nodes {
        content {
          title
        }
        id: instanceId
      }
    }
    data: allSeegnoCmsPayspacesPage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
    stories: allSeegnoCmsStoryEntity {
      nodes {
        content {
          buttonLabel
          description
          storyUrl
          title
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          logo {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          category {
            id
            content {
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * `useFeaturedPayspaces` hook.
 */

function useFeaturedPayspaces(api: ?Object) {
  const [state, setState] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    const apiBaseUrl = api?.baseUrl;
    const endpoint = api?.endpoints?.featuredDomains;

    if (!apiBaseUrl || !endpoint) {
      return;
    }

    setState('loading');

    axios
      .get(resolve(apiBaseUrl, endpoint), {
        params: {
          all: true,
          app: 'website'
        }
      })
      .then(response => {
        setState('success');
        setData(response?.data?.data);
      })
      .catch(() => {
        setState('error');
      });
  }, [api]);

  return {
    data,
    isLoading: state === 'loading'
  };
}

/**
 * `Payspaces` template.
 */

function Payspaces({ pageContext }: TemplateProps): Node {
  const { categories, config, data, stories } = useStaticQuery(configQuery);
  const { data: payspaces, isLoading } = useFeaturedPayspaces(config?.api?.slyk);
  const dataContent = data?.nodes[0]?.content;
  const categorizedStories = groupBy(map(stories.nodes, 'content'), story => story?.category?.id);
  const content = pageContext?.content;
  const metatags = pageContext?.metatags;
  const normalizedCategories = map(categories.nodes, category => ({
    id: category.id,
    title: category?.content?.title
  })).filter(({ id }) => Object.keys(categorizedStories).includes(id));

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={getFluidImage(dataContent?.header, 'backgroundImage')}
        description={content?.header?.description}
        title={content?.header?.title}
      />

      <CategorizedStories
        categories={normalizedCategories}
        stories={categorizedStories}
      />

      <FeaturedPayspacesList
        appUrl={config?.app?.baseUrl}
        featuredList={payspaces}
        isLoading={isLoading}
        title={content?.payspaces?.title}
      />
    </PageContainer>
  );
}

/**
 * Export `Payspaces` container.
 */

export default Payspaces;

// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/core/tabs';
import { map } from 'lodash';
import { media, units } from '@seegno-labs/react-components/styles';
import React, { type Node } from 'react';
import Story, { type StoryProps } from './story';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  categories: Array<{
    id: string,
    title: string
  }>,
  stories: Array<StoryProps>
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  position: relative;
`;

/**
 * `StoriesGrid` styled component.
 */

const StoriesGrid = styled.div`
  display: grid;
  grid-gap: ${units(10)};
  grid-template-columns: 1fr;
  margin-bottom: ${units(10)};
  padding-top: ${units(4)};

  ${media.min('ms')`
    margin-bottom: ${units(14)};
    padding-top: ${units(6)};
  `}

  ${media.min('md')`
    margin-bottom: ${units(20)};
    padding-top: ${units(10)};
  `}
`;

/**
 * `CategorizedStories` component.
 */

function CategorizedStories({ categories, stories }: Props): Node {
  return (
    <Section>
      <Container>
        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnMd={'2 / -2'}
          >
            <Tabs>
              <TabList>
                {map(categories, ({ id, title }) => (
                  <Tab key={id}>
                    {title}
                  </Tab>
                ))}
              </TabList>

              <TabPanels>
                {map(categories, ({ id }) => (
                  <TabPanel key={id}>
                    <StoriesGrid>
                      {map(stories[id], (story, index) => (
                        <Story
                          {...story}
                          invertLayout={index % 2 === 0}
                          key={story.id}
                        />
                      ))}
                    </StoriesGrid>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </Grid>
      </Container>
    </Section>
  );
}

/**
 * Export `CategorizedStories` component.
 */

export default CategorizedStories;

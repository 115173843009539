// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage } from '@seegno-labs/react-components/media';
import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { getAcronym } from 'utils/acronym';
import { map } from 'lodash';
import { slykDomainUrlResolver } from 'utils/url-resolver';
import { theme } from 'styled-tools';
import Loader from 'components/core/loader';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  appUrl: ?string,
  featuredList: ?Array<Object>,
  isLoading: boolean,
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  padding-bottom: ${units(15)};
  position: relative;

  ${media.min('md')`
    padding-bottom: ${units(20)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1)`
  color: ${color('secondary')};
  grid-column: 1 / -1;
  margin-bottom: ${units(4)};
  text-align: center;

  ${media.min('md')`
    grid-column: 2 / -2;
    margin-bottom: ${units(8)};
  `}
`;

/**
 * `List` styled component.
 */

const List = styled.ul`
  display: grid;
  grid-gap: ${units(3)} ${units(4)};

  ${media.min('ms')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('lg')`
    grid-gap: ${units(1)} ${units(8)};
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: logo;
`;

/**
 * `CardLink` styled component.
 */

const CardLink = styled.a`
  align-items: center;
  background: white;
  border-radius: ${units(1)};
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0), 0 2px 10px 1px rgba(0, 0, 0, 0);
  color: ${color('grey700')};
  display: grid;
  grid-gap: ${units(2)};
  grid-template-areas: 'logo info';
  grid-template-columns: min-content 1fr;
  height: 100%;
  padding: ${units(2)};
  text-decoration: none;
  transition: box-shadow ${theme('animations.defaultTransition')};

  :focus,
  :hover  {
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 1px rgba(0, 0, 0, 0.05);
    outline: none;
  }
`;

/**
 * `Info` styled component.
 */

const Info = styled.div`
  grid-area: info;
`;

/**
 * `Logo` styled component.
 */

const Logo = styled.div`
  align-items: center;
  background-color: ${color('secondary')};
  border-radius: ${units(1)};
  clip-path: url(#slyk-mask);
  color: ${color('white')};
  display: flex;
  font-size: 32px;
  height: ${units(10)};
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${units(10)};

  ${media.min('ms')`
    height: 60px;
    width: 60px;
  `}
`;

/**
 * `FeaturedPayspacesList` component.
 */

const FeaturedPayspacesList = (props: Props): Node => {
  const { appUrl, featuredList, isLoading, title } = props;

  return (
    <Section>
      <Container>
        <Grid>
          <Title>
            {title}
          </Title>

          {isLoading ? (
            <Box
              alignItems={'center'}
              display={'flex'}
              gridColumn={'1 / -1'}
              justifyContent={'center'}
            >
              <Loader />
            </Box>
          ) : (
            <Box
              gridColumn={'1 / -1'}
              gridColumnMs={'2 / -2'}
            >
              <List>
                {map(featuredList, ({ id, logo, name, slug, title }) => (
                  <li key={id}>
                    <CardLink
                      href={slykDomainUrlResolver(appUrl, slug, true)}
                      rel={'external noopener'}
                      target={'_blank'}
                    >
                      <ImageWrapper>
                        <Logo>
                          {logo?.url ? <BackgroundImage defaultUrl={logo.url} /> : getAcronym(name)}
                        </Logo>
                      </ImageWrapper>

                      <Info>
                        <Type.Paragraph fontFamily={theme('typography.fontFamily.saira')}>
                          {name}
                        </Type.Paragraph>

                        <Type.Paragraph
                          color={color('grey400')}
                          fontFamily={theme('typography.fontFamily.saira')}
                        >
                          {title}
                        </Type.Paragraph>
                      </Info>
                    </CardLink>
                  </li>
                ))}
              </List>
            </Box>
          )}
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `FeaturedPayspacesList` component.
 */

export default FeaturedPayspacesList;

// @flow

/**
 * Module dependencies.
 */

import {
  Tab as ReachTab,
  TabList as ReachTabList,
  TabPanel as ReachTabPanel,
  TabPanels as ReachTabPanels,
  Tabs as ReachTabs
} from '@reach/tabs';

import { color, units } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * Export `Tabs` styled component.
 */

export const Tabs = styled(ReachTabs)`
  outline: none;
`;

/**
 * Export `TabList` styled component.
 */

export const TabList = styled(ReachTabList)`
  display: flex;
  justify-content: space-between;
  overflow: auto;
  position: relative;
  white-space: nowrap;
`;

/**
 * Export `Tab` styled component.
 */

export const Tab = styled(ReachTab)`
  ${theme('typography.styles.paragraph')}

  background: none;
  border: none;
  border-bottom: 1px solid ${color('grey400')};
  color: inherit;
  cursor: pointer;
  display: inline-block;
  flex: 1;
  margin: 0;
  outline: none;
  padding: ${units(2)};
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, border-color;

  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &:hover,
  &:focus {
    color: ${color('primary')};
  }

  &[data-selected] {
    border-bottom-color: currentColor;
    color: ${color('primary')};
  }
`;

/**
 * Export `TabPanels` styled component.
 */

export const TabPanels = styled(ReachTabPanels)`
  outline: none;
`;

/**
 * Export `TabPanel` styled component.
 */

export const TabPanel = styled(ReachTabPanel)`
  outline: none;
`;
